<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}

                <router-link :to="{ name: 'news.create' }" class="btn btn-success pull-right"
                             title="Добавить" type="button">
                    <i class="fa fa-plus"></i>
                </router-link>
            </h3>
        </div>

        <div class="box-body">
            <card-items :data_search="data_search"
                        :relations="relations"
                        :only_cols="only_cols"
                        component="news-item"
                        item_classes="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 news-grid-item"
                        api_resource="/news">
            </card-items>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import CardItems from './../../components/pagination/card-items';

    Vue.component('news-item', (resolve) => {
        return require(['./item'], resolve);
    });

    export default {
        name: 'news',

        components: {
            CardItems,
        },

        props: {
            data_search: {
                type: String,
                required: false
            }
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Новости',

            relations: [
                'seo',
                'creator',
                'editor',
            ],
            only_cols: [
                'id',
                'slug',
                'img_url',
                'views',
            ],
        }),

        methods: {},
    }
</script>

<style scoped>

</style>
